<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t("staff.staff_head") }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("staff.staff_data") }} </v-card-title>

        <v-form ref="form" v-model="formValid">
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.username" label="username" :rules="formRule.username" outlined dense
                  required />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.password" label="password" :rules="formRule.password" outlined dense
                  required />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.name" :label="$t('staff.staff_name')" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-select v-model="datas.role_id" label="role" :rules="formRule.role_id" :items="roles" item-text="label"
                  outlined dense required />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.line" label="Line" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.email" :rules="formRule.email" type="email" :label="$t('staff.staff_email')"
                  outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.phone" :rules="formRule.phone" :label="$t('staff.staff_phone')" outlined
                  dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'staff' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t("staff.staff_back") }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="createStaff" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t("staff.staff_create") }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
    <!-- <v-col cols="12">
      <v-card>
        <v-card-title> ข้อมูล Staff </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col>
              <v-text-field label="Username" disabled outlined dense />
            </v-col>
            <v-col>
              <v-text-field label="Password" outlined dense type="password" />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'staff' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">ย้อนกลับ</span>
          </v-btn>
          <v-btn color="success" class="px-3">
            <v-icon small>mdi-square-edit-outline</v-icon>
            <span color="primary" class="px-3">สร้าง</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col> -->
  </v-row>
</template>

<script>
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      formValid: true,
      formRule: {
        username: [
          value => !!value || 'username is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
          value => (!!value && value.indexOf(' ') < 0) || 'must have no spaces',
          value => (!!value && !value.toLowerCase().includes('auto')) || 'must not have string "auto"',
          value => (!!value && this.checkNotPhoneNumber(value)) || 'must not have phone number',
        ],
        password: [
          value => !!value || 'password is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        phone: [
          value => !!value || 'phone is required.',
          value => (!!value && value.length === 10) || 'need equal 10 characters',
        ],
        email: [
          value => !!value || 'email is required.',
          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email must be valid'
        ],
        role_id: [
          value => !!value || 'role is required.',
        ],
      },
      datas: {
        role_id: null,
        name: '',
        username: null,
        password: null,
        email: null,
        phone: '',
        line: null,
      },
      permissionDialog: false,
      breadcrumbsItems: [
        {
          text: this.$t("staff.staff_list"),
          disabled: false,
          href: '/staff',
        },
        {
          text: this.$t("staff.staff_add"),
          disabled: true,
          href: '',
        },
      ],
      statusItems: [
        {
          text: 'ผู้ดูแลระบบสูงสุด',
          value: 'superAdmin',
        },
        {
          text: 'ผู้ดูแลระบบ',
          value: 'admin',
        },
        {
          text: 'สมาชิก',
          value: 'member',
        },
      ],
      acceptItems: [
        {
          text: this.$t("yes"),
          value: 'yes',
        },
        {
          text: this.$t("not"),
          value: 'no',
        },
      ],
      loading: false,
      roles: [],
      bankItems: [],
      underUse: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'พนักงาน',
            url: window.location.href,
            detail: 'เพิ่มพนักงาน',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    linkTo(name) {
      const id = this.$route.params
      this.$router.push({ name: name, params: { id } })
    },
    async createStaff() {
      this.loading = true
      if (this.$refs.form.validate()) {
        let params = this.datas
        try {
          await this.$store.dispatch('addEmployee', params)
          this.$router.push({ name: 'staff' })
        } catch (e) {
          if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      } else {
      }
      this.loading = false
    },
    checkNotPhoneNumber(data) {
      data = data.split('')
      let num
      let result = true
      data && data.forEach(el => {
        (Number(el) || Number(el) === 0) && (num ? num = (num + el) : (Number(el) === 0 && (num = el)))
      })
      num?.length >= 10 ? result = false : result = true
      return result
    }
  },
}
</script>